import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useVideoHistory, useVideoHistoryDispatch } from './VideoHistoryContext';
import { loadVideosByIds } from '../../api/load-videos-by-ids';
import { queryKeys } from '../../config/use-query';
import { useAxios } from '../../hooks/use-axios';
export const useContinueWatching = () => {
  const axios = useAxios();
  const videoHistoryDispatch = useVideoHistoryDispatch();
  const {
    continueWatchingIds
  } = useVideoHistory();
  const {
    data: videos,
    error,
    isPending: isLoading
  } = useQuery({
    queryKey: [queryKeys.ContinueWatching(continueWatchingIds), axios],
    queryFn: () => loadVideosByIds(continueWatchingIds, axios)
  });
  useEffect(() => {
    if (videos) {
      videoHistoryDispatch({
        type: 'continue-watching-loaded',
        medias: videos
      });
    }
  }, [videos, videoHistoryDispatch]);
  return {
    videos: videos ?? [],
    error,
    isLoading
  };
};