import { useQuery } from '@tanstack/react-query';
import { useShowState } from './DataContext';
import { loadShowsByIds } from '../../api/home/load-shows-by-ids';
import { queryKeys } from '../../config/use-query';
import { useAxios } from '../../hooks/use-axios';
export const useFavoriteShows = () => {
  const axios = useAxios();
  const {
    favoriteShowIds
  } = useShowState();
  const {
    data = [],
    error,
    isPending: isLoading
  } = useQuery({
    queryKey: [queryKeys.FavoriteShows(favoriteShowIds), axios],
    queryFn: () => loadShowsByIds(favoriteShowIds, axios)
  });
  return {
    favoriteShows: data,
    error,
    isLoading
  };
};