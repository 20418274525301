import { createRequestUrl } from '../../../../play-core/utils/request-url-helper';
export const ShowsByIdsApiPath = '/shows-by-ids';
export const loadShowsByIds = async (ids, axios) => {
  if (ids.length === 0) {
    return Promise.resolve([]);
  }
  const requestUrl = createRequestUrl(ShowsByIdsApiPath, {
    ids
  });
  const {
    data
  } = await axios.get(requestUrl);
  return data.data;
};