import { createRequestUrl } from '../../../play-core/utils/request-url-helper';
export const VideosByIdsApiPath = '/videos-by-ids';
export const loadVideosByIds = async (ids, axios) => {
  if (ids.length === 0) {
    return Promise.resolve([]);
  }
  const requestUrl = createRequestUrl(VideosByIdsApiPath, {
    ids
  });
  const {
    data
  } = await axios.get(requestUrl);
  return data.data;
};