import { useEffect } from 'react';
import { useAnalyticsDispatch } from '../../../analytics/AnalyticsContext';
export const useMyProgramTracking = (hasFavorites, videos) => {
  const analyticsDispatch = useAnalyticsDispatch();
  const hasNoVideos = videos && videos.length === 0;
  useEffect(() => {
    if (hasFavorites) {
      analyticsDispatch({
        type: 'click-event',
        name: 'MyProgramDisplayed',
        value1: 'ShowFavorites'
      });
    }
  }, [hasFavorites, analyticsDispatch]);
  useEffect(() => {
    if (hasFavorites && hasNoVideos) {
      analyticsDispatch({
        type: 'click-event',
        name: 'MyProgramHide',
        value1: 'ShowFavorites',
        value2: 'noVideosDisplayed'
      });
    }
  }, [hasFavorites, hasNoVideos, analyticsDispatch]);
};