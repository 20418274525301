import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useDataDispatch, useWatchLaterState } from './DataContext';
import { loadMediasByUrns } from '../../../../play-core/api/load-medias-by-urns';
import { queryKeys } from '../../config/use-query';
import { useAxios } from '../../hooks/use-axios';
export const useWatchLater = () => {
  const axios = useAxios();
  const {
    videos,
    videoUrns
  } = useWatchLaterState();
  const dataDispatch = useDataDispatch();
  const {
    data,
    error,
    isPending: isLoading
  } = useQuery({
    queryKey: [queryKeys.WatchLater(videoUrns), axios],
    queryFn: () => loadMediasByUrns(videoUrns, axios)
  });
  useEffect(() => {
    if (data) {
      dataDispatch({
        type: 'set-watch-later-videos',
        videos: data
      });
    }
  }, [data, dataDispatch]);
  return {
    videos,
    error,
    isLoading
  };
};