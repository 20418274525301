import { createRequestUrl } from '../utils/request-url-helper';
export const MediasByUrnsApiPath = '/medias-by-urns';
export const loadMediasByUrns = async (urns, axios) => {
  if (urns.length === 0) {
    return Promise.resolve([]);
  }
  const requestUrl = createRequestUrl(MediasByUrnsApiPath, {
    urns
  });
  const {
    data
  } = await axios.get(requestUrl);
  return data.data;
};