import { styled } from 'styled-components';
import { Colors } from '../../../../../play-core/styles/Colors';
import { Fonts } from '../../../../../play-core/styles/Fonts';
import { headingStyle } from '../../../../../play-core/styles/heading-style';
import { Viewports } from '../../../../../play-core/styles/Viewports';
import { SecondaryLink } from '../../../components/ClickableComponents/Link';
export const Layout = styled.div.withConfig({
  displayName: "FavoriteGuideTeaserstyles__Layout",
  componentId: "sc-1h1oabs-0"
})(["display:grid;grid-template-rows:repeat(3,auto);padding-left:32px;padding-right:32px;column-gap:50px;row-gap:14px;justify-items:center;max-width:1500px;margin:auto;padding-top:50px;padding-bottom:50px;", "{grid-template-columns:minmax(330px,1fr) auto;justify-items:start;padding-top:80px;padding-bottom:80px;}", "{column-gap:100px;padding-top:100px;padding-bottom:100px;}"], Viewports.for('mediumUp'), Viewports.for('xLargeUp'));
export const TitleContainer = styled.div.withConfig({
  displayName: "FavoriteGuideTeaserstyles__TitleContainer",
  componentId: "sc-1h1oabs-1"
})(["display:grid;grid-auto-flow:column;grid-template-columns:auto 48px;grid-gap:20px;", "{grid-gap:60px;}"], Viewports.for('mediumUp'));
export const Title = styled.h2.withConfig({
  displayName: "FavoriteGuideTeaserstyles__Title",
  componentId: "sc-1h1oabs-2"
})(["color:", ";", ";align-self:center;margin:0;", "{padding-left:68px;text-align:center;}"], Colors.WHITE, headingStyle, Viewports.for('small'));
export const Image = styled.img.withConfig({
  displayName: "FavoriteGuideTeaserstyles__Image",
  componentId: "sc-1h1oabs-3"
})(["width:100%;align-self:center;", "{grid-row:1 / 4;}"], Viewports.for('mediumUp'));
export const Description = styled.span.withConfig({
  displayName: "FavoriteGuideTeaserstyles__Description",
  componentId: "sc-1h1oabs-4"
})(["", ";line-height:24px;color:", ";", "{text-align:center;}"], Fonts.regular(16), Colors.GREY_969696, Viewports.for('small'));
export const StyledSecondaryLink = styled(SecondaryLink).withConfig({
  shouldForwardProp: () => true
}).withConfig({
  displayName: "FavoriteGuideTeaserstyles__StyledSecondaryLink",
  componentId: "sc-1h1oabs-5"
})(["padding:8px 40px;"]);