import { useMemo } from 'react';
const date = new Date();
const hourlyConstant = (date.getDate() - 1) * date.getHours() % 19; // 0-30 * 0-23 max. 690
const maxResults = 7;

/*
 * sorts ids by hourlyConstant and slice to max. results;
 * prevents changes and refetch on every render cycle ans favorite (de)selection;
 */
export const useRandomShowIds = ids => useMemo(() => ids.map(id => ({
  id,
  order: parseInt(id.replace(/[^\d+]/g, '').slice(-4)) % hourlyConstant
})).sort((a, b) => a.order - b.order).map(value => value.id).slice(0, maxResults), [ids]);