import { styled } from 'styled-components';
import { Colors } from '../../../../../play-core/styles/Colors';
import { Fonts } from '../../../../../play-core/styles/Fonts';
import { Viewports } from '../../../../../play-core/styles/Viewports';
export const IconAndTitle = styled.div.withConfig({
  displayName: "MyVideosTeaserstyles__IconAndTitle",
  componentId: "sc-lequou-0"
})(["display:flex;flex-direction:row;align-items:start;"]);
export const Icon = styled.div.withConfig({
  displayName: "MyVideosTeaserstyles__Icon",
  componentId: "sc-lequou-1"
})(["display:flex;justify-content:center;font-size:44px;color:", ";"], Colors.GREY_d2d2d2);
export const Title = styled.span.withConfig({
  displayName: "MyVideosTeaserstyles__Title",
  componentId: "sc-lequou-2"
})(["padding:8px;color:", ";", ";", "{", ";}"], Colors.GREY_d2d2d2, Fonts.medium(22), Viewports.for('small'), Fonts.medium(18));
export const Labels = styled.p.withConfig({
  displayName: "MyVideosTeaserstyles__Labels",
  componentId: "sc-lequou-3"
})(["padding:8px;margin:0;text-align:center;color:", ";", ";line-height:22px;"], Colors.GREY_d2d2d2, Fonts.regular(16));
export const Text = styled.span.withConfig({
  displayName: "MyVideosTeaserstyles__Text",
  componentId: "sc-lequou-4"
})(["white-space:nowrap;"]);
export const Container = styled.div.withConfig({
  displayName: "MyVideosTeaserstyles__Container",
  componentId: "sc-lequou-5"
})(["position:relative;width:100%;height:100%;border-radius:4px;background-color:", ";&:hover{background-color:", ";}", "{padding-top:", "%;height:auto;}"], Colors.GREY_2a2a2a, Colors.GREY_3a3a3a, Viewports.for('xLargeUp'), 100 / (16 / 9));
export const Layout = styled.div.withConfig({
  displayName: "MyVideosTeaserstyles__Layout",
  componentId: "sc-lequou-6"
})(["position:absolute;top:0;left:0;right:0;bottom:0;overflow:hidden;display:flex;flex-direction:column;align-items:center;justify-content:center;"]);